
import { defineComponent, ref, computed, onMounted } from "vue";

import props from "./props";

import { useStore } from "@/store";

import { useFilledWidthObserver, useTooltipObserver } from "@/hooks/observers";
import useKeyBoardControls from "@/hooks/useKeyboardControls";
import useModelValue from "@/hooks/useModelValue";
import useDragHandler from "@/hooks/useDragHandler";

export default defineComponent({
  name: "vue3-slider",
  props,
  setup(props, { emit }) {
    // error checking
    if (props.modelValue < props.min || props.modelValue > props.max) {
      console.error("[Vue3Slider] Error: value exceeds limits of slider");
    }

    // validate min and max
    if (props.max <= props.min) {
      console.error(
        "[Vue3Slider] Error: Max value cannot be less than or equal to the min value. This will cause unexpected errors to occur, please fix."
      );
    }

    // setup store values
    const store = useStore(props);

    // setup hooks
    const { updateModelValue, formatModelValue } = useModelValue(
      store,
      props,
      emit
    );
    const { handleKeydown } = useKeyBoardControls(
      store,
      props,
      updateModelValue
    );
    const { clickHandler } = useDragHandler(
      store,
      props,
      emit,
      updateModelValue
    );

    // Apply hover styles to handle
    const hovering = ref(false);

    const applyHandleHoverClass = computed((): boolean => {
      if (store.holding.value) {
        return true;
      } else {
        return props.alwaysShowHandle || hovering.value;
      }
    });

    // tooltip setup
    const tooltip = ref<HTMLDivElement>();

    // replace %v with sliders value in tooltip text
    const tooltipText = computed(() => {
      if (!props.tooltip) return "";

      let stringValue = "";

      // if format function is provided then use that
      // else just convert raw value to string
      if (
        props.formatTooltip !== null &&
        typeof props.formatTooltip === "function"
      ) {
        stringValue = props.formatTooltip(
          store.formattedSliderValue.value ||
            formatModelValue(store.modelValueUnrounded.value)
        );
      } else {
        stringValue = (
          store.formattedSliderValue.value ||
          formatModelValue(store.modelValueUnrounded.value)
        ).toString();
      }

      return props.tooltipText.replace("%v", stringValue);
    });

    // watch tooltip width
    const tooltipWidth = ref(0);

    // calculate tooltip offset
    const tooltipOffset = computed(() => {
      if (!props.tooltip) return 0;

      let width: number | undefined = tooltipWidth.value;

      // estimate width if it cant be found
      if (props.orientation !== "horizontal") {
        width = tooltip.value?.clientHeight;

        if (!width) {
          width = 20;
        }

        if (props.orientation !== "vertical") {
          return width;
        }
      } else {
        if (!width) {
          width = 14 + store.formattedSliderValue.value.toString().length * 9;
        } else {
          width += props.height / 3;
        }
      }

      return store.filledWidth.value - width / 2;
    });

    // calculate stroke offset for circular slider
    const circumference = computed(() => {
      if (!store.slider.value || props.orientation !== "circular") return 1;

      return 2 * Math.PI * (store.sliderWidth.value / 2);
    });

    const strokeOffset = computed(() => {
      if (props.orientation !== "circular") return 0;

      return (
        ((store.sliderRange.value - store.modelValueUnrounded.value) /
          store.sliderRange.value) *
        circumference.value
      );
    });

    const vars = computed(() => {
      return {
        "--width": props.width,
        "--height": props.height + "px",
        "--color": props.color,
        "--track-color": props.trackColor,
        "--tooltip-color": props.tooltipColor,
        "--tooltip-text-color": props.tooltipTextColor,
        "--handle-scale": props.handleScale,
      };
    });

    // start observers when component loads
    onMounted(() => {
      useFilledWidthObserver(store, props);
      useTooltipObserver(tooltip, tooltipWidth);
    });

    return {
      filledWidth: store.filledWidth,
      slider: store.slider,
      holding: store.holding,
      flip: computed(() => props.flip),
      clickHandler,
      handleKeydown,
      applyHandleHoverClass,
      hovering,
      showTooltip: computed(() => props.tooltip),
      alwaysShowTooltip: computed(() => props.alwaysShowTooltip),
      tooltip,
      tooltipText,
      tooltipOffset,
      vars,
      circumference,
      strokeOffset,
      circleOffset: computed(() => props.circleOffset),
      sliderValueDegrees: store.sliderValueDegrees,
    };
  },
});
